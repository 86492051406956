@import "~antd/es/dropdown/style/index.less";
@import "~app-variables.less";



@dropdown-prefix-cls: ~'@{ant-prefix}-dropdown';

.@{dropdown-prefix-cls} {
  &-menu {
    &-item,
    &-submenu-title {
      padding: 12px @padding-md;
    }
  }
}
