@mixin position($position, $top: 0, $left: 0) {
  position: $position;
  top: $top;
  left: $left;
}


@mixin respond-to($media) {
  @if $media == small-screens {
    @media only screen and (max-width: $ctr-screen-sm) {
      @content;
    }
  }
}
