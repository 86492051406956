@import "~antd/es/list/style/index.less";
@import "~antd/es/pagination/style/index.less";
@import "~app-variables.less";

@pagination-prefix-cls: ~"@{ant-prefix}-pagination";

.@{pagination-prefix-cls} {
  @ctr-pagination-active-item-bg: @ctr-primary-color;
  @ctr-pagination-active-item-color: @ctr-color-white;

  &-total-text {
    .for-tablet-down({
      display: block;
      height: auto;
      text-align: right;
    });
  }

  &-item {
    &-active {
      background: @ctr-pagination-active-item-bg !important;
      border-color: @ctr-pagination-active-item-bg !important;

      a {
        color: @ctr-pagination-active-item-color !important;
      }

      &:focus,
      &:hover {
        background: @ctr-pagination-active-item-color !important;
      }

      &:focus a,
      &:hover a {
        color: @ctr-pagination-active-item-bg !important;
      }
    }
  }
  &-next,
  &-prev {
    button {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
}

