@import "~app-variables.scss";

.menuItem {
  position: relative;
  line-height: $ctr-layout-header-height;
  color: $ctr-color-grey-5;
  border-bottom: 1px solid $ctr-color-grey-6;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  width: 100%;

  span, a {
    font-size: $ctr-list-item-title-size !important;
  }
  &:last-child {
    border-bottom: none;
  }
}