@import "~app-variables.scss";

.varReminderForm {
  :global {
    .ant-switch{
      background-color: $var-chart-background;
      &:focus {
        box-shadow: 0 0 0 2px rgba(162, 230, 46, 0.1)
      }
    }
    .ant-switch-checked {
      background-color: $var-background-progress;
      box-shadow: none;
    }
    .cs-checkbox {
      display: none;
      height: 0;
      width: 0;
      visibility: hidden;
      &:checked + label {
        background: $var-background-progress;
      }
      &:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }
    }
    .cs-checkbox-label {
      cursor: pointer;
      text-indent: -9999px;
      width: 44px;
      height: 22px;
      background: $var-chart-background;
      display: block;
      border-radius: 90px;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 20px;
        transition: 0.3s;
      }
      &:active:after {
        width: 24px;
      }
    }
    .cs-group-checkbox {
      .ant-checkbox-group-item {
        .ant-checkbox {
          opacity: 0;
          display: none;
        }
        span:nth-child(2) {
          height: 40px;
          width: 40px;
          font-size: $var-font-size-base;
          font-weight: $ctr-font-weight-bold;
          border: 1px solid $ctr-color-grey-4;
          background-color: $ctr-color-white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 10px 0 0;
          border-radius: 50%;
          text-transform: capitalize;
        }
        &.ant-checkbox-wrapper-checked {
          span:nth-child(2) {
            color: $ctr-color-white;
            background: $var-background-progress;
            border-color: $var-background-progress;
          }
        }
      }
    }
    .btn {
      border-radius: 22px;
      background-color: #D5D7DB;
      box-shadow: 0 2px 36px 0 rgb(0 0 0 / 12%);
      width: 110px;
      height: 44px;
      border: none;
      font-size: $var-font-size-base;
      font-weight: $ctr-font-weight-bold-700;
    }
    .btn-submit {
      background-color: #FFC426;
      box-shadow: 0 2px 36px 0 rgba(0,0,0,0.12);
    }
  }
}