@import "~app-variables.scss";

.dfoComp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $ctr-gutter-size;

  &__markReadBtn {
    padding: 0;
    margin: 0;
  }
}
