@import '~app-variables.scss';

.varComp {
  height: $ctr-layout-header-height;
  display: block;
  $parent: &;
  padding: $ctr-gutter-size/2 $ctr-gutter-size*1.5;
  border-bottom: $ctr-border-width-base solid $ctr-color-grey-3;
  &__image {
    height: 100%;
    width: 100%;
    background-image: url(~assets/images/logo_w.png);
    background-repeat: no-repeat;
    background-size: contain;
    &__admin {
      background-image: url(~assets/images/logo-bl.png);
    }
  }

  &__mini {
    padding: $ctr-gutter-size/2;

    #{$parent}__image {
      background-image: url(~assets/images/logo_mini.png);
      background-position: center;
    }
  }
}
