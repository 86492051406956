@import "~app-variables.scss";

.layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 45px;
  padding: 10px 20px 10px 10px;
  border-radius: $ctr-border-radius-base;
  margin: $ctr-gutter-size 0;
}
.dropdown{
  width: 100%;
  border-right: 1px solid $ctr-color-grey-3;
  margin-right: $ctr-gutter-custom-size;
  cursor: pointer;
}
.menu {
  display: flex;
  width: 200px;
  flex-direction: column;

  li:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  i,
  i > svg,
  img {
    color: #2f3133;
    opacity: 1 !important;
  }

  span {
    color: #333c48;
    font-size: 14px;
  }
}