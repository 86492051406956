// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// ***** COLORS *****//
$secondary-color: #16c194;
$base-unit: 16px;

$ctr-color-grey: #ccc;
$ctr-color-grey-1: #edf2f7;
$ctr-color-grey-2: #b7bac0;
$ctr-color-grey-3: #2F3338;
$ctr-color-grey-3-active: #3C4147;
$ctr-color-grey-4: #D5D7DB;
$ctr-color-grey-5: #43474D;
$ctr-color-grey-6: #F5F7FA;
$ctr-color-grey-7: #858D99;
$ctr-color-grey-8: #9BA5B3;
$ctr-color-grey-9: #848D99;

$ctr-color-blue: #366be6;
$ctr-color-white: #fff;
$ctr-color-black: #000;
$varoom-color-btn: #FFC426;

$ctr-primary-color: $ctr-color-blue;
$ctr-secondary-color: $secondary-color;
$ctr-warning-color: #ff8731;
$ctr-danger-color: #e03d3d;
$ctr-primary-color-1: lighten($ctr-primary-color, 20%);
$ctr-primary-color-2: lighten($ctr-primary-color, 40%);
$ctr-primary-color-3: lighten($ctr-primary-color, 60%);
$ctr-primary-color-4: lighten($ctr-primary-color, 80%);

$ctr-background: #edf2f7;
$var-chart-background: #EEF1F5;
$ctr-item-hover-bg: lighten($ctr-primary-color, 40%);

$ctr-section-background: #fff;

$ctr-color-border: #d9d9d9;
$varoom-color-border: #17AFE6;
$varoom-chart-bg: #f3f3f3;

$ctr-text-color: #313c49;
$ctr-text-color-secondary: #6e81a0;
$ctr-text-hint: #8492af;
$ctr-text-color-default: rgba(0, 0, 0, 0.65);

$ctr-grey-color: #c0c6cc;
$ctr-hover-item-border-color: #0064d5;

//*** FONT SIZE **/
$ctr-font-size-small: 13px;
$ctr-font-size-base: 14px;
$var-font-size-base: 15px;
$ctr-font-size-xs: 10px;
$ctr-font-size-sm: 12px;
$ctr-font-size-md: 16px;
$ctr-font-size-lg: 18px;
$ctr-font-size-xlg: 24px;
$ctr-font-size-xlg: 24px;
$ctr-font-size-xlgs: 28px;

//*** FONT WEIGHT **/
$ctr-font-weight-normal: 400;
$ctr-font-weight-bold: 500;
$ctr-font-weight-bold-700: 700;
$ctr-font-weight-bold-900: 900;

// ***** UNITS *****//
$ctr-base-unit: $base-unit;
$ctr-gutter-size: $ctr-base-unit;
$ctr-half-gutter-size: $ctr-gutter-size/2;
$ctr-double-gutter-size: $ctr-gutter-size * 2;
$ctr-triple-gutter-size: $ctr-gutter-size * 3;
$ctr-gutter-custom-size: 20px;
$ctr-menu-collapsed-width: 64px;

// ** BORDER **
$ctr-border-color-base: $ctr-color-grey;
$ctr-border-color-split: #bfc2c7;
$ctr-border-width-base: 1px;
$ctr-border-radius-base: 4px;
$ctr-border-radius-lg: 8px;
$ctr-hover-opacity: 0.8;
$ctr-border-base:  1px solid $ctr-border-color-base;

// ** TABLE **
$ctr-table-header-bg: #dfe5ed;
$ctr-table-header-color: #333;
$ctr-table-padding-vertical: $base-unit/2;
$ctr-table-padding-horizontal: $base-unit;
$ctr-table-selected-row-bg: #bcd9ea;

$ctr-pagination-item-bg-active: $ctr-primary-color;

// ***** FORM - INPUT ***** //
$ctr-form-item-margin-bottom: 24px;

// ** Shadow **
$ctr-shadow-color: rgba(0, 0, 0, 0.15);

$ctr-shadow-1-down: 0 2px 8px $ctr-shadow-color;

$ctr-box-shadow-base: $ctr-shadow-1-down;

// ** Modal **
$ctr-modal-size-sm: 500px;
$ctr-modal-size-md: 700px;
$ctr-modal-size-lg: 85vw;

// Media queries breakpoints
// Extra small screen / phone
$ctr-screen-xs: 480px;
$ctr-screen-xs-min: $ctr-screen-xs;

// Small screen / tablet
$ctr-screen-sm: 576px;
$ctr-screen-sm-min: $ctr-screen-sm;

// Medium screen / desktop
$ctr-screen-md: 768px;
$ctr-screen-md-min: $ctr-screen-md;

// Large screen / wide desktop
$ctr-screen-lg: 992px;
$ctr-screen-lg-min: $ctr-screen-lg;

// Extra large screen / full hd
$ctr-screen-xl: 1200px;
$ctr-screen-xl-min: $ctr-screen-xl;

// Extra extra large screen / large desktop
$ctr-screen-xxl: 1600px;
$ctr-screen-xxl-min: $ctr-screen-xxl;

// provide a maximum
// provide a maximum
$ctr-screen-xs-max: ($ctr-screen-sm-min - 1px);
$ctr-screen-sm-max: ($ctr-screen-md-min - 1px);
$ctr-screen-md-max: ($ctr-screen-lg-min - 1px);
$ctr-screen-lg-max: ($ctr-screen-xl-min - 1px);
$ctr-screen-xl-max: ($ctr-screen-xxl-min - 1px);

// ** SELECT **
$ctr-select-item-selected-bg: $ctr-primary-color-3;
$ctr-select-item-active-bg: $ctr-primary-color-4;

// ** LIST ITEM **
$ctr-list-item-padding: 6px;
$ctr-list-item-title-size: 15px;
$ctr-list-item-background-color: #f2f4f5;

//** APP SHELL LAYOUT
$ctr-layout-header-mobile-height: 86px;

//$ctr-layout-body-background: #eee;
//$ctr-layout-header-background: #0835a6;
$ctr-layout-body-background: #EDF2F7;
$var-background-progress: #A2E62E;
$var-background-progress-inner: #EAF5D7;
$ctr-layout-header-background: #0835A6;
$ctr-layout-footer-background: $ctr-layout-body-background;
$ctr-layout-header-height: 46px;
$ctr-layout-sidebar-width: 240px;
$ctr-layout-sidebar-collapsed-width: 64px;
$ctr-layout-sidebar-z-index: 0;

//TAG
$ctr-tag-default-bg: #989da6;
$ctr-tag-default-color: #fff;
$ctr-tag-default-border-color: #989da6;
$ctr-tag-default-font-size: $ctr-font-size-base;

//INPUT
$ctr-input-border-width-base: 2px;
$ctr-form-label-color: #333;
$ctr-header-color: #333;

//BUILDER
$ctrBuilder__leftSideController--width: 34px;
$ctrBuilder__leftSideController__content--width: 320px;
$ctrBuilder__previewMode__leftSide--width: 200px;
$ctrBuilder__previewMode__mainContent--bg: #edf2f7;
$ctrBuilder__header--height: 60px;


$scrollColor: #babac0;
$scrollBackgroundColor: #ebf0f7;
$backgroundColor: #edf2f7;
$tableHeaderBackgroundColor: $backgroundColor;
$tableHeaderTextColor: #7f8fa4;
$tableHeaderBorder: #b1b7bf;
$headerTextColor: #313c49;
$white: #fff;
$var_main_bg: #212326;
$var_notify_color: #CC0000;

$ctr-menu-title-special-color: #FFC426;
$ctr-menu-title-special-size: 20px;
$ctr-input-border-error-color: #cc4b37;
$ctr-input-background-error-color: #f9ecea;
$ctr-error-message-color: #cc4b37;