@import '~app-variables.scss';

.varComp {
  $size: 34px;
  background-color: $var_main_bg;
  &:hover :global(.ant-layout-sider-trigger) {
    opacity: 1;
  }
  :global .ant-layout-sider-trigger {
    width: $size !important;
    height: $size !important;
    line-height: $size - 3px !important;
    border-radius: 50%;
    top: $ctr-layout-header-height;
    right: -$size/2;
    position: absolute !important;
    background-color: #3C4147;
    border: 2px solid $ctr-color-white;
    opacity: 0;
  }
}
// handle change theme admin roles
.varCompAdmin {
  background-color: $var-chart-background;
  :global {
    .logo {
      border-color: $ctr-color-grey-4;
    }
    .ant-dropdown-user {
      border-color: $ctr-color-grey-4;
    }
    .ant-display-name {
      color: $ctr-color-grey-5;
      p {
        color: $ctr-color-grey-8;
      }
    }
    .ant-anticon-bell svg {
      fill: $ctr-color-grey-5;
    }
    .ant-menu {
      .ant-menu-item {
        background-color: transparent;
        a {
          font-size: 16px;
          color: #43474D !important;
          font-weight: 500;
        }
      }
      .ant-menu-item.ant-menu-item-selected a {
        color: #17AFE6 !important;
      }
    }
  }
}
