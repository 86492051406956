@import '~app-variables.scss';

.varComp {
  $itemHeight__vertical: 45px;
  $itemHeight__horizontal: 55px;
  $itemWidth__horizontal: 150px;
  $itemFontSizeMainMenu_horizontal: 12px;
  $textColor_mainItem: $ctr-color-white;
  $prefix: &;
  $selectedItemBg: #052266;
  $selectedItem_subItemBg: rgba(0, 0, 0, 0.08);
  $selectedItem_HoverBg: rgba(0, 0, 0, 0.1);

  background-color: transparent;
  border-right: 0;
  :global {
    .ant-menu-item {
      width: unset !important;
      margin: 0;
      height: 50px;
      line-height: 50px;
      background: $ctr-color-grey-3;
      margin-bottom: $ctr-border-width-base !important;
      &:first-of-type a{
        color: $ctr-menu-title-special-color !important;
        font-size: $ctr-menu-title-special-size;
      }
    }
    .ant-menu-item.ant-menu-item-selected {
      background-color: $ctr-color-grey-3-active;
      opacity: unset !important;
    }
    .ant-menu-item::after {
      content: unset !important;
    }
  }
}
