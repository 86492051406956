@import "~antd/es/form/style/index.less";
@import "~app-variables.less";


@form-prefix-cls: ~'@{ant-prefix}-form';


.@{form-prefix-cls}-item-required::before {
  display: none !important;
}

.@{form-prefix-cls}-item-required::after {
  display: inline-block !important;
  margin-right: 4px;
  color: #FF4D4F;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*" !important;
}

.@{form-prefix-cls}-item-label > label {
  color: @label-color;
  font-weight: @ctr-font-weight-bold;
}

.tooltipText {
  text-align: left;
}

.question-icon {
  background-color: dodgerblue;
  font-size: small;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-block;
  color: white;
  text-align: center;
}
