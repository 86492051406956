@import "~app-variables.scss";

.avatar {
  flex: 0 0 36px;
  background-color: $ctr-color-white;
  border: 1px solid $ctr-color-white;
}
.fullName {
  color: $ctr-color-white;
  margin: 0 $ctr-font-size-xs;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-weight: 500;
  max-width: 110px;
}
.role {
  font-size: 13px;
  font-weight: $ctr-font-weight-bold;
  letter-spacing: 0;
  line-height: $ctr-gutter-custom-size;
  margin: 0;
}