@import "~app-variables.scss";

.dfoComp {
  color: $ctr-color-white !important;
  &__top {
    padding-left: $ctr-gutter-size;
  }

  :global {
    .ant-badge-count {
      box-shadow: none;
      background-color: $var_notify_color;
      .ant-scroll-number-only {
        height: 16px !important;
        p {
          height: 16px !important;
        }
      }

      @include respond-to(small-screens) {
        font-size: 9px;
        min-width: 16px;
        padding: 0 3px;
        right: 4px;
        border-radius: 10px;
        height: 16px;
      }
    }
  }
  cursor: pointer;
  &_icon {
    font-size: 22px;
    color: $ctr-color-white !important;
  }

  &_label {
    display: inline;
    font-size: 14px;
    text-transform: uppercase;
  }
}
