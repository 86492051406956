@import "~app-variables.scss";

.userTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $ctr-color-grey-5;
  border-bottom: 1px solid #F5F7FA;
  padding: 18px 5px;
  :global {
    h1.ant-typography {
      color: $ctr-color-grey-5;
      font-size: $ctr-font-size-lg;
      font-weight: $ctr-font-weight-bold-900;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
    }
    .ant-typography {
      font-size: $var-font-size-base;
      color: $ctr-color-grey-5;
      &:last-child {
        color: $ctr-color-grey-7;
        font-size: 13px;
      }
    }
  }
}