@import '~app-variables.scss';

.dfoFontS--sm {
  font-size: $ctr-font-size-sm !important;
}

.dfoFontS--md {
  font-size: $ctr-font-size-md !important;
}

.dfoFontS--lg {
  font-size: $ctr-font-size-lg !important;
}

.dfoFontS__res--lg {
  font-size: $ctr-font-size-lg !important;
  @include for-tablet-down {
    font-size: $ctr-font-size-lg - 3px !important;
  }
}

.dfoTextAlign--right {
  text-align: right !important;
}

.dfoTextAlign--center {
  text-align: center !important;
}

.dfoTextTrans--uppercase {
  text-transform: uppercase !important;
}

.dfoTextTrans--capitalize {
  text-transform: capitalize !important;
}

.dfoColor--grey-2 {
  color: $ctr-color-grey-2 !important;
}

.dfoColor--secondary {
  color: $ctr-secondary-color !important;
}

.dfoWordBreak--all {
  word-break: break-all;
}
