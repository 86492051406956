
.ctrPos--sticky {
  position: sticky !important;
  top: 0 !important;
}

.dfoDis--flex {
  display: flex !important;
}

.dfoFlex--1 {
  flex: 1 !important;
}

.dfoFlex-direction--column {
  flex-direction: column !important;
}

.dfoFlex-direction--row {
  flex-direction: row !important;
}

.dfoJustify-content--flex-end {
  justify-content: flex-end !important;
}

.dfoJustify-content--space-between {
  justify-content: space-between !important;
}

.dfoJustify-content--center {
  justify-content: center !important;
}

.dfoAlign-items--center {
  align-items: center !important;
}

.dfoCursor--pointer {
  cursor: pointer !important;
}

.dfoItemHover--bg:hover {
  background-color: $ctr-item-hover-bg;
}

.dfoPos--relative {
  position: relative !important;
}

$extra-small-devices: 575.98px;
$small-devices: 767.98px;
$medium-devices: 991.98px;
$large-devices: 1199.98px;

.dfoContainer {
  //these config & breakpoint is cloned from [bootstrap > .container class]
  width: 100%;
  padding-right: $ctr-gutter-size;
  padding-left: $ctr-gutter-size;
  margin-right: auto;
  margin-left: auto;
  margin-top: $ctr-triple-gutter-size;

  // Extra small devices (portrait phones, less than 576px)
  @media (min-width: $extra-small-devices) {
    max-width: 720px;
  }

  // Small devices (landscape phones, less than 768px)
  @media (min-width: $small-devices) {
    max-width: 720px;
  }

  // Medium devices (tablets, less than 992px)
  @media (min-width: $medium-devices) {
    max-width: 720px;
  }

  // Large devices (desktops, less than 1200px)
  @media (min-width: $large-devices) {
    max-width: 720px;
  }
}

.dfoSizing__width--100 {
  width: 100% !important;
}

.dfoWidth-full-width {
  width: 100%;
}

.dfoWidth-height-full-width {
  width: 100%;
  height: 100%;
}

.dfoList-none-style {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dfoOverflow--hidden {
  overflow: hidden !important;
}
