@import '~app-variables.scss';
//@import "../../styles/animation";


@mixin block ($delay, $left, $top) {
  background: $ctr-primary-color;
  display: block;
  height: 12px;
  opacity: 0;
  position: absolute;
  width: 12px;
  left: $left;
  top: $top;
  @include animation(load, 4.6s, ease-in-out, $delay, infinite);
}
.loadingWrapper {
  $loadingSize: 58px;
  $opacity: 0.9;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 1400;
  align-items: center;
  justify-content: center;
  opacity: $opacity;
  -webkit-tap-highlight-color: #0000;
  position: fixed;
  background: #fff;

  &__loader {
    height: $loadingSize;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    width: $loadingSize;
  }

  &__message {
    // position
    transform: translateY(-100%);
    margin-top: -$loadingSize;

    color: $ctr-primary-color;
    text-align: center;
    font-weight: 700;
    animation: globalLoading__Text 2s;

    max-width: 50vw;
    @media (max-width: $ctr-screen-xs-max) {
      max-width: 70vw;
    }

    @keyframes globalLoading__Text {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &__block1 {
    @include block(1.06s, 0, 0);
  }

  &__block2 {
    @include block(0.97s, 16px, 0);
  }

  &__block3 {
    @include block(0.87s, 31px, 0);
  }

  &__block4 {
    @include block(0.78s, 47px, 0);
  }

  &__block5 {
    @include block(0.69s, 0, 16px);
  }

  &__block6 {
    @include block(0.6s, 16px, 16px);
  }

  &__block7 {
    @include block(0.51s, 31px, 16px);
  }

  &__block8 {
    @include block(0.41s, 47px, 16px);
  }

  &__block9 {
    @include block(0.32s, 0, 31px);
  }

  &__block10 {
    @include block(0.23s, 16px, 31px);
  }

  &__block11 {
    @include block(0.14s, 31px, 31px);
  }

  &__block12 {
    @include block(0.05s, 47px, 31px);
  }

  &__block13 {
    @include block(-0.05s, 0, 47px);
  }

  &__block14 {
    @include block(-0.14s, 16px, 47px);
  }

  &__block15 {
    @include block(-0.23s, 31px, 47px);
  }

  &__block16 {
    @include block(-0.32s, 47px, 47px);
  }
}

