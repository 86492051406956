@import '~app-variables.scss';

.varTabs {
  border: 1px solid $ctr-color-grey-4;
  :global {
    .ant-tabs {
      border: 1px solid $ctr-color-grey-4;
    }
    .ant-tabs-content-holder {
      min-height: 67vh;
    }
    .ant-tabs-content {
      padding: 30px;
      width: 100%;
      max-width: 700px;
      margin: auto;
    }
    .ant-tabs-nav {
      margin-bottom: 0;
    }
    .ant-tabs-nav-wrap {
      border-bottom: 1px solid $ctr-color-grey-4;
      background-color:$ctr-color-grey-6;
    }
    .ant-tabs-nav::before {
      border: none;
    }
    .ant-tabs-tab {
      padding: 14px 16px !important;
      background: $ctr-color-grey-6 !important;
      border: none !important;
      margin: 0 !important;
    }
    .ant-tabs-tab-btn {
      color: $ctr-color-grey-9;
      font-size: $ctr-font-size-lg;
      font-weight: $ctr-font-weight-bold-700;
      text-transform: uppercase;
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $ctr-color-grey-5;
    }
  }
}