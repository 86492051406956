@import "~app-variables.scss";

.menu {
  display: flex;
  width: 270px;
  flex-direction: column;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: overlay;
  li:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  i,
  i > svg,
  img {
    color: #2f3133;
    opacity: 1 !important;
  }

  /*span {
    color: #333c48;
    font-size: $ctr-font-size-base;
  }*/
  :global .ant-typography.ant-typography-time {
    font-size: $ctr-font-size-sm;
    color: $ctr-color-grey-7;
  }
}
.statusItem {
  position: relative;
  cursor: pointer;
  :global {
    .ant-badge-status {
      position: absolute;
      top: 0;
      right: 14px;
    }
  }
}
.dfoCompRead {
  background: rgba(109, 184, 228, 0.18);
  cursor: pointer;
}