@import "~app-variables.less";
@import "~antd/es/modal/style/index.less";
@import "~antd/es/date-picker/style/index.less";
@import "~antd/es/time-picker/style/index.less";


@select-prefix-cls: ~'@{ant-prefix}-picker';
.@{select-prefix-cls} {
  &-ok {
    button {
      background-color: @ctr-color-blue;
    }
  }
}