@import "~antd/es/select/style/index.less";
@import "~app-variables.less";

@select-prefix-cls: ~'@{ant-prefix}-select';

.@{select-prefix-cls} {
  &-selection {
    &--multiple &__choice {
      padding-right: 25px !important;

      &__remove {
        color: @ctr-color-white !important;
      }
    }
  }
}
