@import '~app-variables.scss';

.varComp {
  $prefix: &;
  $textColor_subItem: #2f3133;
  $textColor_mainItem: $ctr-color-grey-4;
  padding-left: 25px;
  font-size: $ctr-font-size-md;
  display: flex;
  align-items: center;
  max-width: 100%;

  &__text,
  &__text:hover {
    text-decoration: none;
  }

  &__subItem {
    #{$prefix}__icon,
    #{$prefix}__text,
    #{$prefix}__text:hover {
      color: $textColor_subItem !important;
    }
  }

  &__inline {
    #{$prefix}__icon,
    #{$prefix}__text,
    #{$prefix}__text:hover {
      color: $textColor_mainItem !important;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
